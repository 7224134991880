(function($) {
    $.fn.elinaTextfield = function() {
        var focusClass = 'is-focused';
        var dirtyClass = 'is-dirty';

        return this.each(function() {
            var self = $(this);
            var input = self.find('.form-textfield__input:not(.bootstrap-select)');
            var label = self.find('.form-textfield__label');

            function checkValue() {
                if (!input.val() || input.val() == '') {
                    self.removeClass(dirtyClass);
                } else {
                    self.addClass(dirtyClass);
                }
            }

            function resizeHeight() {
                input.css('min-height', 0);
                input.css('min-height', (input.prop('scrollHeight') + 1));
            }

            if (self.data('focus-text') && self.data('blur-text') && self.hasClass('is-dirty')) {
                label.text(self.data('focus-text'));
            }
            input.on('focus', function() {
                self.addClass(focusClass);
                if (self.data('focus-text') && self.data('blur-text')) {
                    setTimeout(function() {
                        label.text(self.data('focus-text'));
                    }, 100);
                }
            });
            input.on('blur', function() {
                setTimeout(function() {
                    self.removeClass(focusClass);
                }, 100);

                if (self.data('focus-text') && self.data('blur-text') && input.val() == '') {
                    setTimeout(function() {
                        label.text(self.data('blur-text'));
                    }, 100);
                }
            });
            input.on('change', function() {
                checkValue();
            });

            if (input.is('textarea')) {
                resizeHeight();

                input.on('input', function() {
                    resizeHeight();
                });
            }

            checkValue();

            $(window).on('load', function() {
                checkValue();

                setTimeout(function() {
                    checkValue();
                }, 500);
            });

            $(window).on('resize', function() {
                if (input.is('textarea')) {
                    resizeHeight();
                }
            });
        });
    };
}(jQuery));
