//@import('jquery.teliaForm.js');

function initFormComponents() {
    $(document).trigger('enhance.form');
}

$(document).ready(function() {
    $(document).trigger('enhance.form');
});

window.initFormComponents = initFormComponents;
