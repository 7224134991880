/* ========================================================================
* Bootstrap: collapse.js v3.3.5
* http://getbootstrap.com/javascript/#collapse
* ========================================================================
* Copyright 2011-2015 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
* ======================================================================== */

+function($) {
    'use strict';

    // CSS TRANSITION SUPPORT (Shoutout: http://www.modernizr.com/)
    // ============================================================

    function transitionEnd() {
        var el = document.createElement('bootstrap');

        var transEndEventNames = {
            WebkitTransition: 'webkitTransitionEnd',
            MozTransition: 'transitionend',
            OTransition: 'oTransitionEnd otransitionend',
            transition: 'transitionend'
        };

        for (var name in transEndEventNames) {
            if (el.style[name] !== undefined) {
                return {
                    end: transEndEventNames[name]
                };
            }
        }

        // explicit for ie8 (  ._.)
        return false;
    }

    // http://blog.alexmaccaw.com/css-transitions
    $.fn.emulateTransitionEnd = function(duration) {
        var called = false;
        var $el = this;

        $(this).one('bsTransitionEnd', function() { called = true; });
        function callback() { if (!called) $($el).trigger($.support.transition.end); };
        setTimeout(callback, duration);

        return this;
    };

    $(function() {
        $.support.transition = transitionEnd();

        if (!$.support.transition) return;

        $.event.special.bsTransitionEnd = {
            bindType: $.support.transition.end,
            delegateType: $.support.transition.end,
            handle: function(e) {
                if ($(e.target).is(this)) return e.handleObj.handler.apply(this, arguments);
            }
        };
    });
}(jQuery);

+function($) {
    'use strict';

    /**
     * Collapse
     * @class
     * @param {HTMLElement} element The HTML element the Collapse should be bound to
     * @param {Object} options An option map
     */
    function Collapse(element, options) {
        this.$element      = $(element);
        this.options       = $.extend({}, Collapse.DEFAULTS, options);
        this.id            = element.id;
        this.$trigger      = $('[data-toggle="collapse"][href="#' + this.id + '"],' +
        '[data-toggle="collapse"][data-target="#' + this.id + '"]');
        this.transitioning = null;
        this.$scrollable   = this.$element;

        var collapseContainer = this.$element.find('.collapse__container');

        if (collapseContainer.length) {
            this.$arrow        = $('<div class="collapse__arrow"></div>');
            this.$close        = $('<a href="#" data-toggle="collapse" class="collapse__close"><svg class="icon"><use xlink:href="' + telia.svg_path + '#close-round"></use></svg></a>');

            if (collapseContainer.hasClass('collapse__container--no-close')) {
                this.$close = false;
            }
            if (collapseContainer.hasClass('collapse__container--no-arrow')) {
                this.$arrow = false;
            }
        }

        if (this.options.parent) {
            this.$parent = this.getParent();
        } else {
            this.addAriaAndCollapsedClass(this.$element, this.$trigger);
        }

        if (this.options.toggle) this.toggle();
    };

    Collapse.VERSION  = '3.3.5';

    Collapse.TRANSITION_DURATION = 350;

    Collapse.DEFAULTS = {
        toggle: false,
        scrollSpeed: 500,
        topNavHeight: 50,
        newLocationOffset: 60,
        threshold: 100,
        autoScroll: true,
        // Callback function
        loadContent: function() {}
    };

    /**
     * Get element dimensions.
     * @method dimension
     * @return {String}
     */
    Collapse.prototype.dimension = function() {
        var hasWidth = this.$element.hasClass('width');

        return hasWidth ? 'width' : 'height';
    };

    /**
     * Show collapse.
     * @method show
     * @param  {Boolean} noScroll is scrolling allowed
     */
    Collapse.prototype.show = function(noScroll) {
        var shouldAnimate = true;

        if (this.$element.data('collapse-animate') != undefined) {
            shouldAnimate = this.$element.data('collapse-animate');
        }

        var that = this;

        if ((this.transitioning || this.$element.hasClass('collapse--visible'))) {
            if (!noScroll) {
                this.scroll(false);
            }

            return;
        }

        if (this.$trigger.data('content') == 'ajax') {
            this.options.loadContent.call(this, this.$element, loadContentReady);
        } else {
            showcontent.call(this);
        }

        function loadContentReady(html) {
            that.$element.find('.collapse__container').empty().append(html);
            showcontent.call(that);
        }

        function showcontent() {
            var activesData;
            var actives = that.$parent && this.$parent.find('.collapse--visible, .collapse--collapsing');

            if (actives && actives.length) {
                activesData = actives.data('bs.collapse');
                if (activesData && activesData.transitioning) return;
            }

            var event = $.Event('show.bs.collapse');

            that.$element.trigger(event);
            if (event.isDefaultPrevented()) return;

            if (actives && actives.length) {
                Plugin.call(actives, 'hide');
                activesData || actives.data('bs.collapse', null);
            }

            var dimension = this.dimension();

            if (!shouldAnimate) {
                that.$element
                .addClass('collapse--visible')[dimension](0)
                .attr('aria-expanded', true)
                .prepend(this.$arrow);
            } else {
                that.$element
                .removeClass('collapse')
                .addClass('collapse--collapsing')[dimension](0)
                .attr('aria-expanded', true)
                .prepend(this.$arrow);
            }

            $(document).trigger('refresh.telia', that.$element[0]);

            this.$trigger
            .removeClass('collapsed')
            .attr('aria-expanded', true);

            this.transitioning = 1;

            if (this.$trigger.length) {
                // filters
                this.setArrowPosition();
            }

            function complete() {
                that.$element
                .removeClass('collapse--collapsing')
                .addClass('collapse collapse--visible')[dimension]('');
                this.transitioning = 0;
                that.$element
                .trigger('shown.bs.collapse')
                .prepend(this.$close);

                this.setCloseButtonAction();
            };

            if (!noScroll) {
                this.scroll(true);
            }

            setCollapseText(this.$trigger);

            if (!$.support.transition) return complete.call(this);

            var scrollSize = $.camelCase(['scroll', dimension].join('-'));

            that.$element
            .one('bsTransitionEnd', $.proxy(complete, this))
            .emulateTransitionEnd(Collapse.TRANSITION_DURATION)[dimension](that.$element[0][scrollSize]);

            if (this.$close) {
                this.$close.on('click', function(event) {
                    event.preventDefault();
                });
            }

            $(window).on('resize.telia.collapse.' + this.id, this.resizeHandler.bind(this));
        }
    };

    /**
     * Handle window resize.
     * @method resizeHandler
     */
    Collapse.prototype.resizeHandler = function() {
        if (this.$trigger.length) {
            this.setArrowPosition();
        }
    };

    /**
     * Scroll to collapse.
     * @method scroll
     * @param  {Boolean} setEvent
     */
    Collapse.prototype.scroll = function(setEvent) {
        var event;
        var that = this;

        if (!this.options.autoScroll) {
            return;
        }

        if (setEvent) {
            this.$element.one('shown.bs.collapse', function() {
                scroller();
            });
        } else {
            scroller();
        }

        function scroller() {
            event = $.Event('scroll.bs.collapse');
            that.$element.trigger(event);
            if (!that.$scrollable.isOnScreen(that.options.threshold)) {
                event = $.Event('scrolling.bs.collapse');
                that.$element.trigger(event);
                window.autoScroll(that.$scrollable, that.options.scrollSpeed, that.options.newLocationOffset);
            }

            event = $.Event('scrolled.bs.collapse');
            that.$element.trigger(event);
        }
    };

    /**
     * Hide collapse.
     * @method hide
     */
    Collapse.prototype.hide = function() {
        var shouldAnimate = true;

        if (this.$element.data('collapse-animate') != undefined) {
            shouldAnimate = this.$element.data('collapse-animate');
        }
        if (this.transitioning || !this.$element.hasClass('collapse--visible')) return;

        var event = $.Event('hide.bs.collapse');

        this.$element.trigger(event);
        if (event.isDefaultPrevented()) return;

        var dimension = this.dimension();

        this.$element[dimension](this.$element[dimension]())[0].offsetHeight;

        if (!shouldAnimate) {
            this.$element
            .removeClass('collapse--visible')
            .attr('aria-expanded', false);
        } else {
            this.$element
            .addClass('collapse--collapsing')
            .removeClass('collapse collapse--visible')
            .attr('aria-expanded', false);
        }

        this.$trigger
        .addClass('collapsed')
        .attr('aria-expanded', false);

        this.transitioning = 1;

        function complete() {
            this.transitioning = 0;
            this.$element
            .removeClass('collapse--collapsing')
            .addClass('collapse')
            .trigger('hidden.bs.collapse');
        };

        this.$element.find('.video').trigger('reset');

        setCollapseText(this.$trigger);

        if (!$.support.transition) return complete.call(this);

        this.$element[dimension](0)
        .one('bsTransitionEnd', $.proxy(complete, this))
        .emulateTransitionEnd(Collapse.TRANSITION_DURATION);

        $(window).off('resize.telia.collapse.' + this.id);
    };

    /**
     * Toggle collapse.
     * @method toggle
     */
    Collapse.prototype.toggle = function() {
        this[this.$element.hasClass('collapse--visible') ? 'hide' : 'show']();
    };

    /**
     * Get parent element.
     * @method getParent
     * @return {jQuery} Parent element
     */
    Collapse.prototype.getParent = function() {
        return $(this.options.parent)
        .find('[data-toggle="collapse"][data-parent="' + this.options.parent + '"]')
        .each($.proxy(function(i, element) {
            var $element = $(element);

            this.addAriaAndCollapsedClass(getTargetFromTrigger($element), $element);
        }, this))
        .end();
    };

    /**
     * Add aria and toggle class for trigger
     * @method addAriaAndCollapsedClass
     * @param  {jQuery} $element Collapse element
     * @param  {jQuery} $trigger Collapse trigger element
     */
    Collapse.prototype.addAriaAndCollapsedClass = function($element, $trigger) {
        var isOpen = $element.hasClass('collapse--visible');

        $element.attr('aria-expanded', isOpen);
        $trigger
        .toggleClass('collapsed', !isOpen)
        .attr('aria-expanded', isOpen);
    };

    /**
     * Set arrow position.
     * @method setArrowPosition
     */
    Collapse.prototype.setArrowPosition = function() {
        if (this.$arrow) {
            var left = this.$trigger.offset().left - this.$element.offset().left + this.$trigger.outerWidth() / 2 - 10;

            this.$arrow.css({
                left: left
            });
        }
    };

    /**
     * Set close button link
     * @method setCloseButtonAction
     */
    Collapse.prototype.setCloseButtonAction = function() {
        if (this.$close) {
            this.$close.attr('href', '#' + this.id);
        }
    };

    function getTargetFromTrigger($trigger) {
        var href;
        // strip for ie7
        var target = $trigger.attr('data-target') || (href = $trigger.attr('href')) && href.replace(/.*(?=#[^\s]+$)/, '');

        return $(target);
    }

    function setCollapseText($trigger) {
        $trigger.each(function() {
            var $this = $(this);

            if (!$this.data('more-text') || !$this.data('less-text')) {
                return;
            }
            setTimeout(function() {
                $this.html($this.hasClass('collapsed') ? $this.data('more-text') : $this.data('less-text'));
            }, 100);
        });
    }

    /**
     * Create plugin.
     * @param {Object} options
     * @param {Array[]} args
     * @returns {Array<Collapse>}
     */
    function Plugin(option, args) {
        return this.each(function() {
            var $this   = $(this);
            var data    = $this.data('bs.collapse');
            var options = $.extend({}, Collapse.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data && options.toggle && (/show|hide/).test(option)) options.toggle = false;
            if (!data) $this.data('bs.collapse', (data = new Collapse(this, options)));
            if (typeof option == 'string') data[option](args);
        });
    }

    var old = $.fn.collapse;

    $.fn.collapse             = Plugin;
    $.fn.collapse.Constructor = Collapse;

    // COLLAPSE NO CONFLICT
    // ====================

    $.fn.collapse.noConflict = function() {
        $.fn.collapse = old;

        return this;
    };

    // COLLAPSE DATA-API
    // =================

    /**
     * @param {Event} e
     * @event click.bs.collapseLayer.data-api
     */
    $(document).on('click.bs.collapse.data-api', '[data-toggle="collapse"]', function(e) {
        var $this   = $(this);

        if (!$this.attr('data-target')) e.preventDefault();

        var $target = getTargetFromTrigger($this);
        var data    = $target.data('bs.collapse');
        var option  = data ? 'toggle' : $this.data();

        Plugin.call($target, option);
    });

    /**
     * @param {Event} e
     * @event click.bs.collapseLayer.data-api
     */
    $(document).on('click.bs.collapse.data-api', '[data-show="collapse"]', function(e) {
        var $this   = $(this);

        if (!$this.attr('data-target')) e.preventDefault();

        var $target = getTargetFromTrigger($this);
        var data    = $target.data('bs.collapse');
        var option  = data ? 'show' : $this.data();

        Plugin.call($target, option);
    });

    /**
     * @param {Event} e
     * @event click.bs.collapseLayer.data-api
     */
    $(document).on('click.bs.collapse.data-api', '[data-hide="collapse"]', function(e) {
        var $this   = $(this);

        if (!$this.attr('data-target')) e.preventDefault();

        var $target = getTargetFromTrigger($this);
        var data    = $target.data('bs.collapse');
        var option  = data ? 'hide' : $this.data();

        Plugin.call($target, option);
    });
}(jQuery);
