;(function($, window, document) {
    var pluginName = 'teliaProgress';
    var defaults = {};
    var initSelector = '.progress';

    /**
     * Progress
     * @class
     * @param {HTMLElement} element The HTML element the Progress should be bound to
     * @param {Object} options An option map
     */
    function Progress(element, options) {
        this.element = element;
        this.$element = $(this.element);
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this.progressBreaks = {
            sm: 600,
            md: 1024
        };
        this.currentBreak = this.$element.data('break');
        this.progressWindowWidth = $(window).width();
        this.steps = this.$element.find('.progress__item');
        this.progressContentContainer = this.$element.find('.progress__container');
        this.progressResizeTimer;

        this.init();
    }

    /**
     * Initialize the plugin.
     * @method init
     */
    Progress.prototype.init = function() {
        if (this.currentBreak in this.progressBreaks) {
            var currentProgressBreak = this.progressBreaks[this.currentBreak];

            if (this.progressWindowWidth < currentProgressBreak) {
                this.convertToNarrow();
            }
        } else {
            this.convertToNarrow();
        }

        $(window).on('resize.telia.progress', this.resize.bind(this));
    };

    /**
     * Resize
     * @method resize
     */
    Progress.prototype.resize = function() {
        var that = this;

        clearTimeout(this.progressResizeTimer);
        this.progressResizeTimer = setTimeout(function() {
            var progressWindowWidthNew = $(window).width();

            if (that.currentBreak in that.progressBreaks) {
                var currentProgressBreak = that.progressBreaks[that.currentBreak];

                if (that.progressWindowWidth < currentProgressBreak && progressWindowWidthNew >= currentProgressBreak) {
                    that.convertToWide();
                } else if (that.progressWindowWidth >= currentProgressBreak && progressWindowWidthNew < currentProgressBreak) {
                    that.convertToNarrow();
                }
            }

            that.progressWindowWidth = progressWindowWidthNew;
        }, 250);
    };

    /**
     * Convert progress steps to column view
     * @method convertToNarrow
     */
    Progress.prototype.convertToNarrow = function() {
        if (this.$element.data('break-content') !== undefined && !this.$element.data('break-content')) {
            return;
        }

        this.steps.each(function() {
            var step = $(this);
            var contentID = step.attr('data-content');
            var content = $(contentID);

            step.append(content);
        });

        this.$element.addClass('progress--enhanced');
    };

    /**
     * Convert progress steps to row view
     * @method convertToWide
     * @param {HTMLElement} $parent
     */
    Progress.prototype.convertToWide = function($parent) {
        var container = ($parent && $parent.length) ? $parent : this.progressContentContainer;

        this.steps.each(function() {
            var step = $(this);
            var contentID = step.attr('data-content');
            var content = $(contentID);

            container.append(content);
        });

        this.$element.removeClass('progress--enhanced');
    };

    /**
     * Initialize slick plugin.
     * @method destroy
     */
    Progress.prototype.destroy = function() {
        $(window).off('resize.telia.progress');

        this.$element.removeData('plugin_' + pluginName);
    };

    /**
     * Create jQuery plugin.
     * @param {Object} options
     * @listens telia.progress
     * @returns {Array<Progress>}
     */
    $.fn[pluginName] = function(options) {
        var args = Array.prototype.slice.call(arguments, 1);

        return this.each(function() {
            var instance = $.data(this, 'plugin_' + pluginName);

            if (!instance) {
                $.data(this, 'plugin_' + pluginName, new Progress(this, options));
            } else if (typeof options === 'string') {
                instance[options].apply(instance, args);
            }
        });
    };

    /**
     * @param {Event} event
     * @event enhance.telia.progress
     */
    $(document).on('enhance.telia.progress', function(event) {
        $(event.target).find(initSelector).addBack(initSelector)[pluginName]();
    });

    /**
     * @param {Event} event
     * @event destroy.telia.progress
     */
    $(document).on('destroy.telia.progress', function(event) {
        $(event.target).find(initSelector).addBack(initSelector)[pluginName]('destroy');
    });
})(jQuery, window, document);

/**
 * Auto-init the plugin on DOM ready.
 * @fires enhance.telia.progress
 */
$(function() {
    $(document).trigger('enhance.telia.progress');
});

/**
 * Old init for compatibility.
 * @fires enhance.telia.progress
 * @deprecated Use enhance.telia.progress event directly.
 */
function initProgress() {
    $(document).trigger('enhance.telia.progress');
}
window.initProgress = initProgress;
