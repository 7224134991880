/* ========================================================================
* Bootstrap: tooltip.js v3.3.6
* http://getbootstrap.com/javascript/#tooltip
* Inspired by the original jQuery.tipsy by Jason Frame
* ========================================================================
* Copyright 2011-2015 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
* ======================================================================== */

+function($) {
    'use strict';

    // TOOLTIP PUBLIC CLASS DEFINITION
    // ===============================

    function Tooltip(element, options) {
        this.type       = null;
        this.options    = null;
        this.enabled    = null;
        this.timeout    = null;
        this.hoverState = null;
        this.$element   = null;
        this.inState    = null;

        this.init('tooltip', element, options);
    };

    Tooltip.VERSION  = '3.3.6';

    Tooltip.TRANSITION_DURATION = 150;

    Tooltip.DEFAULTS = {
        animation: true,
        placement: 'top',
        selector: false,
        template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
        trigger: 'hover focus',
        title: '',
        titleSize: 'h4',
        delay: 0,
        html: false,
        container: 'body',
        viewport: {
            selector: 'body',
            padding: 0
        },
        variant: '',
        close: false,
        onload: false,
        class: ''
    };

    Tooltip.prototype.init = function(type, element, options) {
        this.enabled   = true;
        this.type      = type;
        this.$element  = $(element);
        this.options   = this.getOptions(options);
        this.$viewport = this.options.viewport && $($.isFunction(this.options.viewport) ? this.options.viewport.call(this, this.$element) : (this.options.viewport.selector || this.options.viewport));
        this.inState   = {
            click: false,
            hover: false,
            focus: false
        };
        this.$close  = $('<a href="#" class="popover__close"><svg class="icon"><use xlink:href="' + telia.svg_path + '#close-round"></use></svg></a>');

        if (this.$element[0] instanceof document.constructor && !this.options.selector) {
            throw new Error('`selector` option must be specified when initializing ' + this.type + ' on the window.document object!');
        }

        var triggers = this.options.trigger.split(' ');

        for (var i = triggers.length; i--;) {
            var trigger = triggers[i];

            if (trigger == 'click') {
                this.$element.on('click.' + this.type, this.options.selector, $.proxy(this.toggle, this));
                this.options.close = true;
            } else if (trigger != 'manual') {
                var eventIn  = trigger == 'hover' ? 'mouseenter' : 'focusin';
                var eventOut = trigger == 'hover' ? 'mouseleave' : 'focusout';

                this.$element.on(eventIn  + '.' + this.type, this.options.selector, $.proxy(this.enter, this));
                this.$element.on(eventOut + '.' + this.type, this.options.selector, $.proxy(this.leave, this));
            }
        }

        if (this.options.selector) {
            this._options = $.extend({}, this.options, {
                trigger: 'manual',
                selector: ''
            });
        } else {
            this.fixTitle();
        }
    };

    Tooltip.prototype.getDefaults = function() {
        return Tooltip.DEFAULTS;
    };

    Tooltip.prototype.getOptions = function(options) {
        options = $.extend({}, this.getDefaults(), this.$element.data(), options);

        if (options.delay && typeof options.delay == 'number') {
            options.delay = {
                show: options.delay,
                hide: options.delay
            };
        }

        return options;
    };

    Tooltip.prototype.getDelegateOptions = function() {
        var options  = {};
        var defaults = this.getDefaults();

        this._options && $.each(this._options, function(key, value) {
            if (defaults[key] != value) options[key] = value;
        });

        return options;
    };

    Tooltip.prototype.enter = function(obj) {
        var self = obj instanceof this.constructor ? obj : $(obj.currentTarget).data('bs.' + this.type);

        if (!self) {
            self = new this.constructor(obj.currentTarget, this.getDelegateOptions());
            $(obj.currentTarget).data('bs.' + this.type, self);
        }

        if (obj instanceof $.Event) {
            self.inState[obj.type == 'focusin' ? 'focus' : 'hover'] = true;
        }

        if (self.tip().hasClass('popover--visible') || self.hoverState == 'popover--visible') {
            self.hoverState = 'popover--visible';

            return;
        }

        clearTimeout(self.timeout);

        self.hoverState = 'popover--visible';

        if (!self.options.delay || !self.options.delay.show) return self.show();

        self.timeout = setTimeout(function() {
            if (self.hoverState == 'popover--visible') self.show();
        }, self.options.delay.show);
    };

    Tooltip.prototype.isInStateTrue = function() {
        for (var key in this.inState) {
            if (this.inState[key]) return true;
        }

        return false;
    };

    Tooltip.prototype.leave = function(obj) {
        var self = obj instanceof this.constructor ? obj : $(obj.currentTarget).data('bs.' + this.type);

        if (!self) {
            self = new this.constructor(obj.currentTarget, this.getDelegateOptions());
            $(obj.currentTarget).data('bs.' + this.type, self);
        }

        if (obj instanceof $.Event) {
            self.inState[obj.type == 'focusout' ? 'focus' : 'hover'] = false;
        }

        if (self.isInStateTrue()) return;

        clearTimeout(self.timeout);

        self.hoverState = 'out';

        if (!self.options.delay || !self.options.delay.hide) return self.hide();

        self.timeout = setTimeout(function() {
            if (self.hoverState == 'out') self.hide();
        }, self.options.delay.hide);
    };

    Tooltip.prototype.show = function() {
        function complete() {
            var prevHoverState = that.hoverState;

            that.$element.trigger('shown.bs.' + that.type);
            that.hoverState = null;

            if (prevHoverState == 'out') that.leave(that);

            $(document).on('keyup.telia.tooltip.' + that.$tip[0].id, that.keyboard.bind(that));
        };

        var e = $.Event('show.bs.' + this.type);

        if (this.hasContent() && this.enabled) {
            this.$element.trigger(e);

            var inDom = $.contains(this.$element[0].ownerDocument.documentElement, this.$element[0]);

            if (e.isDefaultPrevented() || !inDom) return;
            var that = this;

            var $tip = this.tip();

            var tipId = this.getUID(this.type);

            this.setContent();
            $tip.attr('id', tipId);
            this.$element.attr('aria-describedby', tipId);

            if (this.options.close) {
                if (this.options.title == '') {
                    $tip.addClass('popover--with-close');
                }
                $tip.addClass('popover--with-close-and-title');
                $tip.append(this.$close);

                this.$close.on('click', function(e) {
                    e.preventDefault();
                    that.hide();
                    that.inState.click = false;
                });
            }

            if (this.options.animation) $tip.addClass('popover--fade');

            var placement = typeof this.options.placement == 'function' ? this.options.placement.call(this, $tip[0], this.$element[0]) : this.options.placement;

            $tip
            .detach()
            .css({
                top: 0,
                left: 0,
                display: 'block'
            })
            .data('bs.' + this.type, this);

            if (this.options.variant) {
                var variants = this.options.variant.split(' ');

                for (var i = 0; i < variants.length; i++) {
                    var variant = variants[i];

                    if (variant && variant != 'default') {
                        $tip.addClass('popover--' + variant);
                        if (variant == 'blue') {
                            $tip.find('.popover__arrow').removeClass('popover__arrow').addClass('popover__arrow-triangle');
                        }
                    }
                }
            }

            if (this.options.class) {
                var classes = this.options.class.split(' ');

                for (var j = 0; j < classes.length; j++) {
                    var oneclass = classes[j];

                    if (oneclass) {
                        $tip.addClass(oneclass);
                    }
                }
            }

            if (this.$element.closest('.modal').length) {
                if (this.options.container == Tooltip.DEFAULTS.container) {
                    this.options.container = this.$element.closest('.modal');
                }
            }

            this.options.container ? $tip.appendTo(this.options.container) : $tip.insertAfter(this.$element);
            this.$element.trigger('inserted.bs.' + this.type);

            var pos          = this.getPosition();
            var actualWidth  = $tip[0].offsetWidth;
            var actualHeight = $tip[0].offsetHeight;
            var tp;
            var fallbackPosition = 'top';

            // Get positions
            var tpt = {
                top: pos.top - actualHeight,
                left: pos.left + pos.width / 2 - actualWidth / 2
            };
            var tpb = {
                top: pos.top + pos.height,
                left: pos.left + pos.width / 2 - actualWidth / 2
            };
            var tpl = {
                top: pos.top + pos.height / 2 - actualHeight / 2,
                left: pos.left - actualWidth
            };
            var tpr = {
                top: pos.top + pos.height / 2 - actualHeight / 2,
                left: pos.left + pos.width
            };

            var headerHeight = 0;

            if (!this.$tip.hasClass('popover--top') && $('.header').length) {
                headerHeight = $('.header').height();
            }
            // Get position room
            var hast = (tpt.top > $(window).scrollTop() + headerHeight);
            var hasb = ((tpb.top + actualHeight) < ($(window).scrollTop() + $(window).height()));
            var hasl = (tpl.left > $(window).scrollLeft());
            var hasr = ((tpr.left + actualWidth) < ($(window).scrollLeft() + $(window).width()));

            switch (placement) {
                case 'top':
                    if (!hast) {
                        if (hasb) {
                            placement = 'bottom';
                        } else if (hasr) {
                            placement = 'right';
                        } else if (hasl) {
                            placement = 'left';
                        } else {
                            placement = fallbackPosition;
                        }
                    }
                    break;
                case 'bottom':
                    if (!hasb) {
                        if (hast) {
                            placement = 'top';
                        } else if (hasr) {
                            placement = 'right';
                        } else if (hasl) {
                            placement = 'left';
                        } else {
                            placement = fallbackPosition;
                        }
                    }
                    break;
                case 'left':
                    if (!hasl) {
                        if (hasr) {
                            placement = 'right';
                        } else if (hast) {
                            placement = 'top';
                        } else if (hasb) {
                            placement = 'bottom';
                        } else {
                            placement = fallbackPosition;
                        }
                    }
                    break;
                case 'right':
                    if (!hasr) {
                        if (hasl) {
                            placement = 'left';
                        } else if (hast) {
                            placement = 'top';
                        } else if (hasb) {
                            placement = 'bottom';
                        } else {
                            placement = fallbackPosition;
                        }
                    }
                    break;
            }

            switch (placement) {
                case 'top':
                    tp = tpt;
                    break;
                case 'bottom':
                    tp = tpb;
                    break;
                case 'left':
                    tp = tpl;
                    break;
                case 'right':
                    tp = tpr;
                    break;
            }

            this.applyPlacement(tp, placement);

            $tip.addClass(placement);

            if ($.support.transition && this.$tip.hasClass('popover--fade')) {
                $tip.one('bsTransitionEnd', complete).emulateTransitionEnd(Tooltip.TRANSITION_DURATION);
            } else {
                complete();
            }
        }
    };

    Tooltip.prototype.applyPlacement = function(offset, placement) {
        var $tip   = this.tip();
        var width  = $tip[0].offsetWidth;
        var height = $tip[0].offsetHeight;

        // manually read margins because getBoundingClientRect includes difference
        var marginTop = parseInt($tip.css('margin-top'), 10);
        var marginLeft = parseInt($tip.css('margin-left'), 10);

        // we must check for NaN for ie 8/9
        if (isNaN(marginTop))  marginTop  = 0;
        if (isNaN(marginLeft)) marginLeft = 0;

        offset.top  += marginTop;
        offset.left += marginLeft;

        // $.fn.offset doesn't round pixel values
        // so we use setOffset directly with our own function B-0
        $.offset.setOffset($tip[0], $.extend({
            using: function(props) {
                $tip.css({
                    top: Math.round(props.top),
                    left: Math.round(props.left)
                });
            }
        }, offset), 0);

        $tip.addClass('popover--visible');

        // check to see if placing tip in new offset caused the tip to resize itself
        var actualWidth  = $tip[0].offsetWidth;
        var actualHeight = $tip[0].offsetHeight;

        if (placement == 'top' && actualHeight != height) {
            offset.top = offset.top + height - actualHeight;
        }

        var delta = this.getViewportAdjustedDelta(placement, offset, actualWidth, actualHeight);

        if (delta.left) offset.left += delta.left;
        else offset.top += delta.top;

        var isVertical          = (/top|bottom/).test(placement);
        var arrowDelta          = isVertical ? delta.left * 2 - width + actualWidth : delta.top * 2 - height + actualHeight;
        var arrowOffsetPosition = isVertical ? 'offsetWidth' : 'offsetHeight';

        if (!this.options.onload) {
            $tip.offset(offset);
        }
        this.replaceArrow(arrowDelta, $tip[0][arrowOffsetPosition], isVertical);
    };

    Tooltip.prototype.replaceArrow = function(delta, dimension, isVertical) {
        if (!this.options.onload) {
            this.arrow()
            .css(isVertical ? 'left' : 'top', 50 * (1 - delta / dimension) + '%')
            .css(isVertical ? 'top' : 'left', '');
        }
    };

    Tooltip.prototype.setContent = function() {
        var $tip  = this.tip();
        var title = this.getTitle();

        $tip.find('.tooltip-inner')[this.options.html ? 'html' : 'text'](title);
        $tip.removeClass('popover--fade popover--visible top bottom left right');
    };

    Tooltip.prototype.hide = function(callback) {
        var that = this;
        var $tip = $(this.$tip);
        var e    = $.Event('hide.bs.' + this.type);

        function complete() {
            if (that.hoverState != 'popover--visible') $tip.detach();
            that.$element
            .removeAttr('aria-describedby')
            .trigger('hidden.bs.' + that.type);
            callback && callback();
        }

        if (this.$tip) {
            $(document).off('keyup.telia.tooltip.' + this.$tip[0].id);
        }
        this.$element.trigger(e);

        if (e.isDefaultPrevented()) return;

        $tip.removeClass('popover--visible');

        if ($.support.transition && $tip.hasClass('popover--fade')) {
            $tip.one('bsTransitionEnd', complete).emulateTransitionEnd(Tooltip.TRANSITION_DURATION);
        } else {
            complete();
        }

        this.hoverState = null;

        return this;
    };

    Tooltip.prototype.fixTitle = function() {
        var $e = this.$element;

        if ($e.attr('title') || typeof $e.attr('data-original-title') != 'string') {
            $e.attr('data-original-title', $e.attr('title') || '').attr('title', '');
        }
    };

    Tooltip.prototype.hasContent = function() {
        return this.getTitle();
    };

    Tooltip.prototype.getPosition = function($element) {
        $element   = $element || this.$element;

        var el     = $element[0];
        var isBody = el.tagName == 'BODY';

        var elRect    = el.getBoundingClientRect();

        if (elRect.width == null) {
            // width and height are missing in IE8, so compute them manually; see https://github.com/twbs/bootstrap/issues/14093
            elRect = $.extend({}, elRect, {
                width: elRect.right - elRect.left,
                height: elRect.bottom - elRect.top
            });
        }
        var elOffset  = isBody ? {
            top: 0,
            left: 0
        } : $element.offset();
        var scroll    = {
            scroll: isBody ? document.documentElement.scrollTop || document.body.scrollTop : $element.scrollTop()
        };
        var outerDims = isBody ? {
            width: $(window).width(),
            height: $(window).height()
        } : null;

        return $.extend({}, elRect, scroll, outerDims, elOffset);
    };

    Tooltip.prototype.getCalculatedOffset = function(placement, pos, actualWidth, actualHeight) {
        var ret = {
            top: pos.top + pos.height / 2 - actualHeight / 2,
            left: pos.left + pos.width
        };

        if (placement == 'bottom') {
            ret = {
                top: pos.top + pos.height,
                left: pos.left + pos.width / 2 - actualWidth / 2
            };
        } else if (placement == 'top') {
            ret = {
                top: pos.top - actualHeight,
                left: pos.left + pos.width / 2 - actualWidth / 2
            };
        } else if (placement == 'left') {
            ret = {
                top: pos.top + pos.height / 2 - actualHeight / 2,
                left: pos.left - actualWidth
            };
        }

        return ret;
    };

    Tooltip.prototype.getViewportAdjustedDelta = function(placement, pos, actualWidth, actualHeight) {
        var delta = {
            top: 0,
            left: 0
        };

        if (!this.$viewport) return delta;

        var viewportPadding = this.options.viewport && this.options.viewport.padding || 0;
        var viewportDimensions = this.getPosition(this.$viewport);

        if (/right|left/.test(placement)) {
            var topEdgeOffset    = pos.top - viewportPadding - viewportDimensions.scroll;
            var bottomEdgeOffset = pos.top + viewportPadding - viewportDimensions.scroll + actualHeight;

            if (topEdgeOffset < viewportDimensions.top) {
                // top overflow
                delta.top = viewportDimensions.top - topEdgeOffset;
            } else if (bottomEdgeOffset > viewportDimensions.top + viewportDimensions.height) {
                // bottom overflow
                delta.top = viewportDimensions.top + viewportDimensions.height - bottomEdgeOffset;
            }
        } else {
            var leftEdgeOffset  = pos.left - viewportPadding;
            var rightEdgeOffset = pos.left + viewportPadding + actualWidth;

            if (leftEdgeOffset < viewportDimensions.left) {
                // left overflow
                delta.left = viewportDimensions.left - leftEdgeOffset;
            } else if (rightEdgeOffset > viewportDimensions.right) {
                // right overflow
                delta.left = viewportDimensions.left + viewportDimensions.width - rightEdgeOffset;
            }
        }

        return delta;
    };

    Tooltip.prototype.getTitle = function() {
        var title;
        var $e = this.$element;
        var o  = this.options;

        title = $e.attr('data-original-title')          ||
        (typeof o.title == 'function' ? o.title.call($e[0]) :  o.title);

        return title;
    };

    Tooltip.prototype.getUID = function(prefix) {
        do prefix += ~~(Math.random() * 1000000);
        while (document.getElementById(prefix));

        return prefix;
    };

    Tooltip.prototype.tip = function() {
        if (!this.$tip) {
            this.$tip = $(this.options.template);
            if (this.$tip.length != 1) {
                throw new Error(this.type + ' `template` option must consist of exactly 1 top-level element!');
            }
        }

        return this.$tip;
    };

    Tooltip.prototype.arrow = function() {
        return (this.$arrow = this.$arrow || this.tip().find('.tooltip-arrow'));
    };

    Tooltip.prototype.enable = function() {
        this.enabled = true;
    };

    Tooltip.prototype.disable = function() {
        this.enabled = false;
    };

    Tooltip.prototype.toggleEnabled = function() {
        this.enabled = !this.enabled;
    };

    Tooltip.prototype.toggle = function(e) {
        var self = this;

        if (e) {
            self = $(e.currentTarget).data('bs.' + this.type);
            if (!self) {
                self = new this.constructor(e.currentTarget, this.getDelegateOptions());
                $(e.currentTarget).data('bs.' + this.type, self);
            }
        }

        if (e) {
            self.inState.click = !self.inState.click;
            if (self.isInStateTrue()) self.enter(self);
            else self.leave(self);
        } else {
            self.tip().hasClass('popover--visible') ? self.leave(self) : self.enter(self);
        }
    };

    Tooltip.prototype.keyboard = function(event) {
        if (event.keyCode == 27) {
            this.hide();
        }
    },

    Tooltip.prototype.destroy = function() {
        var that = this;

        clearTimeout(this.timeout);
        this.hide(function() {
            that.$element.off('.' + that.type).removeData('bs.' + that.type);
            if (that.$tip) {
                that.$tip.detach();
            }
            that.$tip = null;
            that.$arrow = null;
            that.$viewport = null;
        });
    };

    // TOOLTIP PLUGIN DEFINITION
    // =========================

    function Plugin(option) {
        return this.each(function() {
            var $this   = $(this);
            var data    = $this.data('bs.tooltip');
            var options = typeof option == 'object' && option;

            if (!data && (/destroy|hide/).test(option)) return;
            if (!data) $this.data('bs.tooltip', (data = new Tooltip(this, options)));
            if (typeof option == 'string') data[option]();
        });
    }

    var old = $.fn.tooltip;

    $.fn.tooltip             = Plugin;
    $.fn.tooltip.Constructor = Tooltip;

    // TOOLTIP NO CONFLICT
    // ===================

    $.fn.tooltip.noConflict = function() {
        $.fn.tooltip = old;

        return this;
    };
}(jQuery);

/* ========================================================================
* Bootstrap: popover.js v3.3.6
* http://getbootstrap.com/javascript/#popovers
* ========================================================================
* Copyright 2011-2015 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
* ======================================================================== */

+function($) {
    'use strict';

    // POPOVER PUBLIC CLASS DEFINITION
    // ===============================

    function Popover(element, options) {
        this.init('popover', element, options);
    };

    if (!$.fn.tooltip) throw new Error('Popover requires tooltip.js');

    Popover.VERSION  = '3.3.6';

    Popover.DEFAULTS = $.extend({}, $.fn.tooltip.Constructor.DEFAULTS, {
        placement: 'top',
        trigger: 'click',
        content: '',
        template: '<div class="popover" role="tooltip"><div class="popover__arrow"></div><h4 class="popover__title"></h4><div class="popover__content"></div></div>'
    });

    // NOTE: POPOVER EXTENDS tooltip.js
    // ================================

    Popover.prototype = $.extend({}, $.fn.tooltip.Constructor.prototype);

    Popover.prototype.constructor = Popover;

    Popover.prototype.getDefaults = function() {
        return Popover.DEFAULTS;
    };

    Popover.prototype.setContent = function() {
        var $tip    = this.tip();
        var title   = this.getTitle();
        var content = this.getContent();
        var $title  = $tip.find('.popover__title');

        $title[this.options.html ? 'html' : 'text'](title);
        if (this.options.titleSize) {
            $title.addClass(this.options.titleSize);
        }
        if (this.options.html == 'object') {
            content = $(this.$element.data('content')).html();
            $tip.find('.popover__content').children().detach().end()['html'](content);
        } else {
            var method = 'text';

            // we use append for html objects to maintain js events
            if (this.options.html) {
                method = typeof content == 'string' ? 'html' : 'append';
            }
            $tip.find('.popover__content').children().detach().end()[method](content);
        }

        $tip.removeClass('popover--fade top bottom left right popover--visible');

        // IE8 doesn't accept hiding via the `:empty` pseudo selector, we have to do
        // this manually by checking the contents.
        if (!$title.html()) $title.hide();
    };

    Popover.prototype.hasContent = function() {
        return this.getTitle() || this.getContent();
    };

    Popover.prototype.getContent = function() {
        var $e = this.$element;
        var o  = this.options;

        return $e.attr('data-content') || (typeof o.content == 'function' ? o.content.call($e[0]) : o.content);
    };

    Popover.prototype.arrow = function() {
        return (this.$arrow = this.$arrow || this.tip().find('.popover__arrow, .popover__arrow-triangle'));
    };

    // POPOVER PLUGIN DEFINITION
    // =========================

    function Plugin(option) {
        return this.each(function() {
            var $this   = $(this);
            var data    = $this.data('bs.popover');
            var options = typeof option == 'object' && option;

            if (!data && (/destroy|hide/).test(option)) return;
            if (!data) $this.data('bs.popover', (data = new Popover(this, options)));
            if (typeof option == 'string') data[option]();
        });
    }

    var old = $.fn.popover;

    $.fn.popover             = Plugin;
    $.fn.popover.Constructor = Popover;

    // POPOVER NO CONFLICT
    // ===================

    $.fn.popover.noConflict = function() {
        $.fn.popover = old;

        return this;
    };
}(jQuery);

function initPopover() {
    $(document).trigger('enhance.tooltip');
}
window.initPopover = initPopover;

$(document).on('enhance.tooltip', function(event) {
    var initSelector = '[data-toggle="popover"]';

    $(event.target).find(initSelector).each(function() {
        var self = $(this);

        self.popover();

        if (self.data('onload')) {
            self.popover('show');
        }
    });

    var popoverResizeTimer;
    var cachedWindowWidth = $(window).width();

    $(window).on('resize', function() {
        var newWindowWidth = $(window).width();

        if (newWindowWidth != cachedWindowWidth) {
            clearTimeout(popoverResizeTimer);
            popoverResizeTimer = setTimeout(function() {
                $('.popover').each(function() {
                    var $this = $(this);

                    if ($this.is(':visible')) {
                        $this.addClass('popover--reset-transition');
                        $('#' + $this.attr('id')).popover('show');
                        $this.removeClass('popover--reset-transition');
                    }
                });
            }, 300);

            cachedWindowWidth = newWindowWidth;
        }
    });

    $(document).on('click touchstart', function(e) {
        $(initSelector).each(function() {
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                $(this).popover('hide');
            }
        });
    });

    $(document).on('hidden.bs.popover', function(e) {
        $(e.target).data('bs.popover').inState.click = false;
    });
});

$(function() {
    $(document).trigger('enhance.tooltip');
});
