//@import('jquery.teliaButton.js');

function initButton() {
    $(document).trigger('enhance.button');
}
window.initButton = initButton;

$(function() {
    $(document).trigger('enhance.button');
});
