(function() {
    'use strict';

    $.fn.weekDatepicker = function() {
        var $trigger = $(this);
        var pickerClass = 'weekdatepicker';
        var activeClass = 'active';

        function _setPosition($el) {
            var triggerDims = _getDimensions($trigger);
            var elDims = _getDimensions($el);
            var left;
            var top;
            var bodyRect;
            var triggerRect;
            var pointerRight = 3;

            left = triggerDims.width - elDims.width;
            top = triggerDims.top + triggerDims.height + 10;

            bodyRect = $('body')[0].getBoundingClientRect();
            triggerRect = $trigger[0].getBoundingClientRect();

            if ((triggerRect.right + 15) < bodyRect.right) {
                left += 15;
                pointerRight += 15;
            }

            $($el[0].firstElementChild).css({
                right: pointerRight
            });

            $el.css({
                left: left,
                top: top
            });
        };

        function _getDimensions($el) {
            var offset = $el.position();

            return {
                width: $el.outerWidth(),
                height: $el.outerHeight(),
                left: offset.left,
                top: offset.top
            };
        };

        function show($el) {
            _setPosition($el);
            $el.addClass(activeClass);
        };

        function hide($el) {
            $el.removeClass(activeClass).css({
                left: '-10000px'
            });
        };

        return this.each(function() {
            var $this = $(this);
            var $el = $this.parent().siblings('.' + pickerClass);

            $this.on('focus', function() {
                show($el);
            });
            $this.on('blur', function() {
                hide($el);
            });
        });
    };
}());
