(function($) {
    $.fn.elinaConditionalDataValidator = function() {
        var elinaConditionalDataValidator = {
            run: function(obj, name, value, ignorePreback) {
                var self = this;
                var groups = $('[data-conditional-group="' + name + '"]');

                groups.each(function() {
                    var group = $(this);

                    group.addClass('hidden');
                    if (group.attr('data-conditional-preback') && !ignorePreback) {
                        if (typeof window[group.attr('data-conditional-preback')] == 'function') {
                            if (window[group.attr('data-conditional-preback')]({
                                target: obj,
                                group: group
                            })) {
                                return setTimeout(function() { self.run(obj, name, value, true); }, 50);
                            }
                        }
                    }
                    var condition = group.attr('data-conditional-' + name + '-value');

                    if (condition == 'any' && value !== false) {
                        group.removeClass('hidden');
                    } else if (value !== false) {
                        var conditions = condition.split(',');

                        if ($.inArray(value, conditions) !== -1) {
                            group.removeClass('hidden');
                        } else if (group.find('input:checked').length > 0) {
                            self.run(obj, group.find('input:checked').prop('checked', false).attr('name'), false);
                        }
                    }
                    if (group.attr('data-conditional-callback')) {
                        if (typeof window[group.attr('data-conditional-callback')] == 'function') {
                            window[group.attr('data-conditional-callback')]({
                                target: obj,
                                group: group
                            });
                        }
                    }
                    // trigger resize to refresh equalheight etc
                    $(window).trigger('resize');
                });
            }
        };

        return this.each(function() {
            $(this).on('change', function() {
                if ($(this).is(':checked')) {
                    elinaConditionalDataValidator.run($(this), $(this).attr('name'), $(this).val());
                } else {
                    elinaConditionalDataValidator.run($(this), $(this).attr('name'), false);
                }
            });
        });
    };
}(jQuery));
