;(function($, window, document) {
    var pluginName = 'teliaCollapseTrigger';
    var defaults = {};
    var initSelector = '[data-collapse-trigger]';
    var breakpoints = {
        xs: '(min-width: 0px)',
        sm: '(min-width: 600px)',
        md: '(min-width: 1024px)',
        lg: '(min-width: 1200px)'
    };

    function CollapseTrigger(element, options) {
        this.element = element;
        this.$element = $(this.element);
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this.value = jQuery.parseJSON(this.$element.attr('data-collapse-trigger'));
        this.$target = this.getTargetFromTrigger();
        this.userHasManipulated = false;

        this.init();
    }

    /*
    Initialize the plugin.
    */

    CollapseTrigger.prototype.init = function() {
        this.toggleCollapse();

        this.$element.on('click.telia.collapseTrigger', this.clickHandler.bind(this));
        $(window).on('resize.telia.collapseTrigger.' + this.$target[0].id, this.toggleCollapse.bind(this));
    };

    /*
    Toggle the collapse.
    */

    CollapseTrigger.prototype.toggleCollapse = function() {
        if (!this.userHasManipulated) {
            if (this.checkMedia()) {
                this.$target.collapse('show', true);
            } else {
                this.$target.collapse('hide');
            }
        }
    };

    /*
    Check if current media condition matches element rules.
    */

    CollapseTrigger.prototype.checkMedia = function() {
        var open = false;

        for (var key in this.value) {
            if (this.value.hasOwnProperty(key)) {
                if (window.matchMedia(breakpoints[key]).matches) {
                    if (this.value[key]) {
                        open = true;
                    } else {
                        open = false;
                    }
                }
            }
        }

        return open;
    };

    /*
    On click, set userHasManipulated to true to disaallow responsive logic.
    */

    CollapseTrigger.prototype.clickHandler = function() {
        this.userHasManipulated = true;
        this.destroy();
    };

    /*
    Get target collapse element from trigger.
    */

    CollapseTrigger.prototype.getTargetFromTrigger = function() {
        var href;
        var target = this.$element.attr('data-target') || (href = this.$element.attr('href')) && href.replace(/.*(?=#[^\s]+$)/, '');

        return $(target);
    };

    /*
    Destroy the plugin instance.
    */

    CollapseTrigger.prototype.destroy = function() {
        this.$element.off('click.telia.collapseTrigger');
        $(window).off('resize.telia.collapseTrigger.' + this.$target[0].id);
        this.$element.removeData('plugin_' + pluginName);
    };

    $.fn[pluginName] = function(options) {
        var args = Array.prototype.slice.call(arguments, 1);

        return this.each(function() {
            var instance = $.data(this, 'plugin_' + pluginName);

            if (!instance) {
                $.data(this, 'plugin_' + pluginName, new CollapseTrigger(this, options));
            } else if (typeof options === 'string') {
                instance[options].apply(instance, args);
            }
        });
    };

    $(document).on('enhance.telia.collapseTrigger', function(event) {
        $(event.target).find(initSelector)[pluginName]();
    });

    $(document).on('destroy.telia.collapseTrigger', function(event) {
        $(event.target).find(initSelector)[pluginName]('destroy');
    });
})(jQuery, window, document);

/*
Auto-init the plugin on DOM ready.
*/

$(function() {
    $(document).trigger('enhance.telia.collapseTrigger');
});
