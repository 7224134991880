/**
 * Show overlay
 * @method showOverlay
 * @param {string} container Container selector
 * @param {string} classes List of overlay classes
 * @param {boolean} loader Enable loader
 * @param {string} loaderText Loader text
 * @returns {jQuery|HTMLElement} overlay element
 */
function showOverlay(container, classes, loader, loaderText) {
    if (!container) {
        container = 'body';
    }

    var overlay = $('<div class="overlay ' + classes + '"></div>');

    if (loader) {
        var spinner = $('<div class="spinner overlay__spinner"><div class="spinner__loader"></div></div>');

        if (loaderText) {
            spinner.append('<div class="spinner__text">' + loaderText + '</div>');
        }

        overlay.append(spinner);
    }

    $(container).append(overlay);

    return overlay;
}

/**
 * Hide overlay
 * @method hideOverlay
 * @param {jQuery|HTMLElement} overlay jQuery element
 * @returns {void}
 */
function hideOverlay(overlay) {
    if (overlay) {
        overlay.remove();
    } else {
        $('body > .overlay').remove();
    }
}

window.showOverlay = showOverlay;
window.hideOverlay = hideOverlay;
