;(function($, window, document) {
    var pluginName = 'teliaHeader';
    var defaults = {};
    var initSelector = '.header';

    /**
     * Header
     * @class
     * @param {HTMLElement} element The HTML element the Header should be bound to
     * @param {Object} options An option map
     */
    function Header(element, options) {
        this.element = element;
        this.$element = $(this.element);
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this.container = false;
        this.$iframe = this.$element.find('.header__iframe');

        this.init();
    }

    /**
     * Initialize the plugin.
     * @method init
     */
    Header.prototype.init = function() {
        this.$iframe.iFrameResize({
            checkOrigin: false
        });
    };

    /**
     * Destroy the plugin instance.
     * @method destroy
     */
    Header.prototype.destroy = function() {
        this.$element.removeData('plugin_' + pluginName);
    };

    /**
     * Create jQuery plugin.
     * @param {Object} options
     * @listens telia.header
     * @returns {Array<Header>}
     */
    $.fn[pluginName] = function(options) {
        var args = Array.prototype.slice.call(arguments, 1);

        return this.each(function() {
            var instance = $.data(this, 'plugin_' + pluginName);

            if (!instance) {
                $.data(this, 'plugin_' + pluginName, new Header(this, options));
            } else if (typeof options === 'string') {
                instance[options].apply(instance, args);
            }
        });
    };

    /**
     * @param {Event} event
     * @event enhance.telia.header
     */
    $(document).on('enhance.telia.header', function(event) {
        $(event.target).find(initSelector).addBack(initSelector)[pluginName]();
    });

    /**
     * @param {Event} event
     * @event destroy.telia.header
     */
    $(document).on('destroy.telia.header', function(event) {
        $(event.target).find(initSelector).addBack(initSelector)[pluginName]('destroy');
    });
})(jQuery, window, document);

/**
 * Auto-init the plugin on DOM ready.
 * @fires enhance.telia.header
 */
$(function() {
    $(document).trigger('enhance.telia.header');
});
