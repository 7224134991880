/**
 * Start loader
 * @param  {string} container  Container selector
 * @param  {string} classes    List of classes for overlay
 * @param  {string} loaderText Loader text
 * @returns {Function}            showOverlay function
 */
function startLoad(container, classes, loaderText) {
    if (!classes && !container) {
        classes = 'overlay--fixed';
    }

    return window.showOverlay(container, classes, true, loaderText);
}

/**
 * End loader
 * @param  {JQuery} overlay Overlay element
 * @returns {void}
 */
function endLoad(overlay) {
    window.hideOverlay(overlay);
}

window.startLoad = startLoad;
window.endLoad = endLoad;
