;(function($, window, document) {
    var pluginName = 'teliaModal';
    var defaults = {};
    var initSelector = '.modal';

    /**
     * Modal
     * @class
     * @param {HTMLElement} element The HTML element the Modal should be bound to
     * @param {Object} options An option map
     */
    function Modal(element, options) {
        this.element = element;
        this.$element = $(this.element);
        this.options = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this.container = false;

        this.init();
    }

    /**
     * Initialize the plugin.
     * @method init
     */
    Modal.prototype.init = function() {};

    /**
     * Open modal
     * @method open
     * @param  {HTMLElement} trigger
     */
    Modal.prototype.open = function(trigger) {
        if ($('.modal-container--visible').length > 0) {
            $('.modal-container--visible').each(function() {
                $(this).find(initSelector)[pluginName]('close');
            });
        }

        var that = this;
        var e = $.Event('open.telia.modal');

        this.$element.trigger(e);

        if (!this.container.length) {
            this.container = $('<div class="modal-container"></div>');
            this.inner = $('<div class="modal-container__inner"></div>');

            this.container.on('click.telia.modal.close', function(event) {
                if ($(event.target).parents('body').length && $(event.target).closest('.modal').length == 0) {
                    event.preventDefault();
                    that.close();
                }
            });

            // force re-rendering of icons to fix a very weird chrome bug
            this.$element.find('.icon').each(function() {
                $(this).html($(this).html());
            });

            this.inner.append(this.$element);
            this.container.append(this.inner);

            $('body').append(this.container);
        }

        if (this.$element.find('.modal__close').length == 0) {
            this.closeBtn = $('<button class="modal__close"><svg class="icon">' +
            '<use xlink:href="' + telia.svg_path + '#close-round"></use>' +
            '</svg></button>');

            this.$element.prepend(this.closeBtn);

            this.closeBtn.on('click.telia.modal.close', function(event) {
                event.preventDefault();
                that.close();
            });
        }

        this.container.addClass('modal-container--visible');
        $(document).trigger('refresh.telia', [this.element, trigger]);

        window.disableScroll(true);
        $(document).on('keyup.telia.modal', this.keyboard.bind(this));
    };

    /**
     * Close modal on esc
     * @method keyboard
     * @param  {Event} event
     */
    Modal.prototype.keyboard = function(event) {
        if (event.keyCode == 27) {
            this.close();
        }
    };

    /**
     * Close modal
     * @method close
     */
    Modal.prototype.close = function() {
        this.$element.find('.video').trigger('reset');
        this.container.removeClass('modal-container--visible');
        window.enableScroll();
        var e = $.Event('close.telia.modal');

        this.$element.trigger(e);
        $(document).off('keyup.telia.modal');
    };

    /**
     * Destroy the plugin instance.
     * @method destroy
     */
    Modal.prototype.destroy = function() {
        this.$element.removeData('plugin_' + pluginName);
    };

    /**
     * Create jQuery plugin.
     * @param {Object} options
     * @listens telia.modal
     * @returns {Array<Modal>}
     */
    $.fn[pluginName] = function(options) {
        var args = Array.prototype.slice.call(arguments, 1);

        return this.each(function() {
            var instance = $.data(this, 'plugin_' + pluginName);

            if (!instance) {
                $.data(this, 'plugin_' + pluginName, new Modal(this, options));
            } else if (typeof options === 'string') {
                instance[options].apply(instance, args);
            }
        });
    };

    /**
     * @param {Event} event
     * @event enhance.telia.modal
     */
    $(document).on('enhance.telia.modal', function(event) {
        $(event.target).find(initSelector).addBack(initSelector)[pluginName]();
    });

    /**
     * @param {Event} event
     * @event destroy.telia.modal
     */
    $(document).on('destroy.telia.modal', function(event) {
        $(event.target).find(initSelector).addBack(initSelector)[pluginName]('destroy');
    });

    /**
     * @param  {Event} event
     * @event click.telia.modal.open
     */
    $(document).on('click.telia.modal.open', '.js-modal', function(event) {
        event.preventDefault();
        var $this = $(this);
        var trigger = $(event.currentTarget);
        var target = false;

        if ($this.attr('data-modal-target')) {
            target = $this.attr('data-modal-target');
        } else if ($this.attr('href')) {
            target = $this.attr('href');
        }

        if (target) {
            $(target)[pluginName]('open', trigger);
        }
    });
})(jQuery, window, document);

/**
 * Auto-init the plugin on DOM ready.
 * @fires enhance.telia.modal
 */
$(function() {
    $(document).trigger('enhance.telia.modal');
});

/**
 * Old init for compatibility.
 * @fires enhance.telia.modal
 * @deprecated Use enhance.telia.modal event directly.
 */
function initModal() {
    $(document).trigger('enhance.telia.modal');
}
window.initModal = initModal;
